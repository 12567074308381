.folder__modal .modal-header {
	border-bottom: none;
}

.folder__modal .modal-header > img {
	margin-right: 9px;
}

.folder__modal .modal-body > div {
	display: flex;
	flex-wrap: wrap;
}
