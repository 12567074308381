.home {
	height: 87%;
}
.home__welcomeDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}
.home__welcomeDiv code {
	color: #808da0;
}
.home__welcomeDiv img {
	margin-bottom: 15px;
}

.home > h3 {
	margin-left: 15px;
	margin-bottom: 0;
	padding-top: 15px;
}

.home__files {
	display: flex;
	flex-wrap: wrap;
}

.home__uploadButton {
	position: fixed;
	bottom: 15px;
	right: 15px;
	border-radius: 12px;
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
	background: linear-gradient(268.78deg, #001351 -0.02%, #002499 99.8%);
}
