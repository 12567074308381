.plans {
	padding: 15px;
}

.plans__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.plan {
	border-radius: 24px;
	padding: 18px;
	margin: 15px;
	min-width: 300px;
	text-align: center;
	background-color: #f8f8f8;
}

.plan.active {
	border: 3px solid #002397;
}

.plan > img {
	max-width: 120px;
}
.plan > h3 {
	font-weight: 700;
}
.plan > h3 > span {
	color: #002397;
}
.plan > button {
	background: linear-gradient(268.78deg, #001351 -0.02%, #002499 99.8%);
	border-radius: 24px;
	margin-top: 15px;
	min-width: 210px;
}
.plan > div {
	text-align: left;
	padding-left: 30px;
}

.paypal__checkout {
	text-align: center;
}
