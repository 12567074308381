.nav__userIcon {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.nav__userIcon > svg {
	margin-right: 6px;
}

.popover-body {
	padding: 0;
}

.user__popoverListItem {
	cursor: pointer;
	width: 100%;
	padding: 12px 15px;
	min-width: 150px;
}
.user__popoverListItem:hover {
	background-color: #f7f7f7;
}
.user__popoverListItem > p {
	margin-bottom: 6px;
}

.progress {
	height: 6px;
}
