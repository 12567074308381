.login {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 15px;
}

.login > img {
	max-width: 180px;
	margin: 12px auto;
}

.login > form {
	margin: 15px;
}

.login > form > button {
	background: linear-gradient(268.78deg, #001351 -0.02%, #002499 99.8%);
	border-radius: 24px;
	max-width: 390px;
	min-height: 54px;
	width: 100%;
}
.login__input svg {
	position: relative;
	top: 39px;
	left: 9px;
}
.login__input input {
	padding-left: 45px;
	background: #fafafa;
	border-radius: 12px;
	min-height: 54px;
	border: none;
}

.login__bottomForm {
	margin-top: 15px;
	text-align: center;
}
.login__bottomForm > button {
	background-color: #fff;
	border: none;
	width: 100%;
	margin: 12px auto;
	max-width: 390px;
	box-shadow: 0px 4px 60px rgba(0, 35, 151, 0.1);
	border-radius: 24px;
}
.login__bottomForm > button > img {
	max-width: 42px;
}

.login__bottomForm > button:hover {
	background-color: #fff;
}
