html {
	height: 100%;
}

body {
	margin: 0;
	font-family: "Urbanist";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

*:focus {
	outline: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

main {
	width: 100%;
	overflow: scroll;
}

aside {
	position: sticky !important;
	top: 0;
	height: 100%;
}

.app {
	height: 100%;
	display: flex;
}

:root {
	--toastify-color-progress-light: linear-gradient(
		268.78deg,
		#001351 -0.02%,
		#002499 99.8%
	);
}
