.file__container {
	background: #fafafa;
	border-radius: 24px;
	cursor: pointer;
	margin: 15px;
	padding: 12px;
	width: 180px;
}
.file__container > p {
	margin-bottom: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.file__container > span {
	color: #878787;
	letter-spacing: 0.2px;
	font-size: small;
}
.file__container > svg {
	width: 39px;
	height: 39px;
}
