.upload__modal .modal-header {
	border-bottom: none;
}

.upload__modal .modal-body {
	padding-top: 0;
}

.upload__modal .modal-body button {
	background-color: #00c650;
	border: none;
	border-radius: 12px;
	float: right;
}
.upload__modal .modal-body input {
	border-radius: 12px;
	margin: 12px auto;
}
.upload__modal .modal-body select {
	border-radius: 12px;
}
.upload__modal .modal-body label {
	font-size: small;
	color: #878787;
}
