.ps-menu-label {
	display: flex;
	justify-items: flex-start;
	align-items: center;
}

.ps-menu-label > span {
	margin-left: 24px;
	text-align: left;
}

.app__sidebarMenu {
	display: flex;
	height: 100vh;
	flex-direction: column;
	justify-content: space-between;
}

.app__sidebarMenuTop {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.app__sidebarMenuTop > img {
	max-width: 120px;
	margin: 18px auto;
}

.app__sidebarMenuBottom {
	border-top: 1px solid #efefef;
	padding-bottom: 15px;
}
